<template lang="pug">
  .root
    component-header-index
    div(class="container-flex")
      div(class="content-left")
        component-top
      div(class="content-right")
        div(class="right-content-border")

          button(@click="onRefresh()" class="reload-button") <i class="fas fa-sync-alt"></i>
          button(@click="onClose()" class="close-button") <i class="fas fa-times"></i>

          div(v-if="open_list.length > 0" class="event-item")
            h5 現在参加中のイベント
            div(v-for="item in open_list" class="event-img-item")
              div(v-if="item.type === 't3'" class="red-r-label event-img-item-label") OD
              div(v-if="item.type === 't2'" class="orange-r-label event-img-item-label") 準OD
              img(:src="$store.state.system.config.storage_addr_list.public + item.banner_store_path" @click="onImage(item.id)")

          div(v-if="feature_list.length > 0" class="event-item")
            h5 今後参加予定のイベント
            div(v-for="item in feature_list" class="event-img-item")
              div(v-if="item.type === 't3'" class="red-r-label event-img-item-label") OD
              div(v-if="item.type === 't2'" class="orange-r-label event-img-item-label") 準OD
              img(:src="$store.state.system.config.storage_addr_list.public + item.banner_store_path" @click="onImage(item.id)")

          div(v-if="over_list.length > 0" class="event-item")
            h5 終了済みのイベント
            div(v-for="item in over_list" class="event-img-item")
              div(v-if="item.type === 't3'" class="red-r-label event-img-item-label") OD
              div(v-if="item.type === 't2'" class="orange-r-label event-img-item-label") 準OD
              img(:src="$store.state.system.config.storage_addr_list.public + item.banner_store_path" @click="onImage(item.id)")

          div(class="no-event-text" v-if="isNoEvent") 参加予定のイベントはありません。
    component-footer-index
</template>

<style src="./Index.scss" lang="scss" scoped>
</style>

<script>
import { getLiveListEventEntryALL } from '@/api/live'
import moment from 'moment'

export default {
  name: "SettingEventManageDetail",
  components: { },
  data() {
    return {
      open_list: [],
      feature_list: [],
      over_list: []
    }
  },
  props: {},
  computed: {
    isNoEvent() {
      return this.open_list.length == 0 && this.feature_list.length == 0 && this.over_list.length == 0 
    }
  },
  watch: {},
  mounted() {},
  async created() {
    await this.init();
  },
  updated() {},
  destroyed() {},
  methods: {
    async init() {
      this.fetchList()
      //fetch event notice
      this.$store.dispatch('event/syncEventNotice')
    },
    async fetchList(){
      this.open_list = [];
      this.feature_list = [];
      const entry_events = await getLiveListEventEntryALL();
      const now = moment();
      entry_events.data.data.forEach(live => {
        let start_time = moment(live['start_time']);
        let end_time = moment(live['end_time']);
        if (now.isAfter(start_time) && now.isBefore(end_time)) {
          this.open_list.push(live);
        } else if (now.isAfter(end_time)) {
          this.over_list.push(live) 
        } else if(now.isBefore(start_time)){
          this.feature_list.push(live);
        }
      });
    },
    async onRefresh() {
      this.fetchList()
    },
    onClose() {
      this.$router.go(-1)
    },
    onImage(id) {
      this.$router.push({ name: 'SettingEventManageDetail', params: {
        id
      } });
    }
  }
};
</script>
